import {
  API_TRANSACTION_SEARCH,
  API_TRANSACTION_STATUSES,
  API_TRANSACTION_TEMPLATE_DELETE,
  API_TRANSACTION_TEMPLATES,
  API_TRANSACTION_TYPES,
  API_TRANSACTIONS,
} from "@/constants/api";
import type { Transaction, TransactionTemplate } from "@/common/types";
import { TransactionData } from "@/common/types"; // @ts-ignore
import dayjs from "dayjs";
import api from "@/composables/useApi";
import { useCoreDataStore } from "@/stores/useCoreDataStore";
import { storeToRefs } from "pinia";
import { useCustomerStore } from "@/stores/useCustomerStore";

export function useTransactions() {
  const coreDataStore = useCoreDataStore();
  const { transactionTypeIncome, transactionStatusUnpaid } = storeToRefs(coreDataStore);

  const loadTransactionStatuses = async () => {
    return api.get(API_TRANSACTION_STATUSES).then(({ data: { data } }) => {
      return data;
    });
  };

  const loadTransactionTypes = async () => {
    return api.get(API_TRANSACTION_TYPES).then(({ data: { data } }) => {
      return data;
    });
  };

  const addTransaction = async (transaction: TransactionData | Transaction): Promise<Transaction | TransactionTemplate> => {
    if (isTransaction(transaction)) {
      return updateTransaction(transaction);
    }
    return api.post(API_TRANSACTIONS, { transaction: transaction }).then(({ data }) => {
      if (data.status == "success") {
        return data.data;
      } else {
        return false;
      }
    });
  };

  const updateTransaction = async (transaction: Transaction) => {
    return api
      .put(API_TRANSACTIONS, {
        transaction: transaction,
      })
      .then(({ data }) => {
        if (data.status === "success") {
          return data.data;
        }
      });
  };

  const deleteTransaction = async (transaction: Transaction) => {
    return api.delete(`${API_TRANSACTIONS}/${transaction.id}`).then(({ data }) => {
      if (data.status === "success") {
        return data.data;
      }
    });
  };

  const searchTransactions = async (
    reference?: string,
    transactionTypeId?: number,
    transactionStatusId?: number,
    start?: Date,
    end?: Date,
  ): Promise<Array<Transaction>> => {
    return api
      .post(API_TRANSACTION_SEARCH, {
        search: reference,
        transactionTypeId,
        transactionStatusId,
        start: start ? dayjs(start).unix() : null,
        end: end ? dayjs(end).unix() : null,
      })
      .then(({ data: { data } }) => {
        data = data.map((transaction: Transaction) => {
          if (transaction.transactionDate) {
            transaction.transactionDate = new Date(transaction.transactionDate);
          }
          if (transaction.transactionTemplate?.startDate) {
            transaction.transactionTemplate.startDate = new Date(transaction.transactionTemplate.startDate);
          }
          if (transaction.transactionTemplate?.endDate) {
            transaction.transactionTemplate.endDate = new Date(transaction.transactionTemplate.endDate);
          }
          return transaction;
        });
        return data;
      });
  };

  const updateTransactions = async (transactions: Array<Transaction>) => {
    return api
      .put(`${API_TRANSACTIONS}`, {
        transactions,
      })
      .then(({ data }) => {
        if (data.status === "success") {
          return data.data;
        }
      });
  };

  const getTransactionTemplates = async () => {
    return api.get(API_TRANSACTION_TEMPLATES).then(({ data: { data } }) => {
      return data;
    });
  };

  const updateTransactionTemplate = async (transactionTemplate: TransactionTemplate, dateFrom?: Date) => {
    return api
      .put(`${API_TRANSACTION_TEMPLATES}/${transactionTemplate.id}`, {
        transactionTemplate: transactionTemplate,
        dateFrom: dateFrom,
      })
      .then(({ data: { data } }) => {
        return data;
      });
  };

  const deleteTransactionTemplate = async (transactionTemplate: TransactionTemplate, dateFrom?: Date) => {
    return api
      .put(`${API_TRANSACTION_TEMPLATE_DELETE}/${transactionTemplate.id}`, {
        dateFrom: dateFrom ? dayjs(dateFrom).unix() : null,
      })
      .then(({ data: { data } }) => {
        return data;
      });
  };

  const isTransaction = (input: string | Transaction | TransactionData): input is Transaction => {
    return (input as Transaction)?.id !== undefined || (input as Transaction).transactionTemplateId !== undefined;
  };

  const moveTransaction = async (transaction: Transaction, transactionDate: string): Promise<Transaction> => {
    return api
      .put(`${API_TRANSACTIONS}/move`, {
        transaction,
        transactionDate,
      })
      .then(({ data: { data } }) => {
        return data;
      });
  };

  /**
   * Asynchronously retrieves the total amount of due income transactions.
   * If transaction type or status is not defined, loads core data from storage.
   *
   * @returns A Promise that resolves to a number representing the total amount of due income transactions.
   */
  const getDueIncomeTransactionAmount = async (): Promise<number> => {
    if (!transactionTypeIncome.value || !transactionStatusUnpaid.value) {
      await coreDataStore.loadCoreData();
    }
    const customerStore = useCustomerStore();
    const { incomeBankAccount, customer } = storeToRefs(customerStore);

    // startDate is last transaction update data of the income bank account

    const lastUpdate = incomeBankAccount.value?.lastUpdate ?? customer.value?.lastTransactionsUpdate ?? new Date();

    return searchTransactions(undefined, transactionTypeIncome.value?.id, transactionStatusUnpaid.value?.id, lastUpdate, new Date()).then((data) => {
      return data.reduce((totalAmount: number, transaction: Transaction) => {
        return totalAmount + transaction.amount;
      }, 0);
    });
  };

  return {
    loadTransactionStatuses,
    loadTransactionTypes,
    addTransaction,
    updateTransaction,
    deleteTransaction,
    searchTransactions,
    updateTransactions,
    getTransactionTemplates,
    updateTransactionTemplate,
    deleteTransactionTemplate,
    moveTransaction,
    isTransaction,
    getDueIncomeTransactionAmount,
  };
}
