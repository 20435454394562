import { API_BANK_ACCOUNT_ROLES, API_BANK_ACCOUNT_TYPES, API_BANK_ACCOUNT_UPDATE_BALANCE, API_BANK_ACCOUNTS } from "@/constants/api";
import type { BankAccount, BankAccountRole, BankAccountType, WeekDelta } from "@/common/types";
import { newBankAccount } from "@/common/types";
import { ref } from "vue";
import api from "@/composables/useApi";
import { dayjs } from "@/utils/dayjs";

export function useBankAccounts() {
  const bankAccountTypes = ref<Array<BankAccountType>>();

  const getBankAccounts = async (): Promise<Array<BankAccount>> => {
    const now = dayjs();

    return api.get(API_BANK_ACCOUNTS).then(({ data: { data } }) => {
      return data.map((bankAccount: BankAccount) => {
        if (bankAccount.lastUpdate) {
          bankAccount.lastUpdate = new Date(bankAccount.lastUpdate);
          const lastUpdate = dayjs(bankAccount.lastUpdate);
          bankAccount.notUpdatedRecently = now.diff(lastUpdate, "week") >= 2;
        }
        if (bankAccount.pendingSplit) {
          bankAccount.pendingSplit = parseFloat(`${bankAccount.pendingSplit}`);
        }

        return bankAccount;
      });
    });
  };

  const getBankAccountTypes = async (): Promise<Array<BankAccountType>> => {
    return api.get(API_BANK_ACCOUNT_TYPES).then(({ data: { data: data } }) => {
      return data;
    });
  };

  const getBankAccountType = (code: String) => {
    if (bankAccountTypes.value) {
      return bankAccountTypes.value?.find((bankAccountType: BankAccountType) => bankAccountType.code === code);
    }
  };

  const getBankAccountRoles = async (): Promise<Array<BankAccountRole>> => {
    return api.get(API_BANK_ACCOUNT_ROLES).then(({ data: { data: data } }) => {
      return data;
    });
  };

  const updateBalance = async (bankAccount: BankAccount) => {
    return api.put(API_BANK_ACCOUNT_UPDATE_BALANCE, {
      bankAccountId: bankAccount.id,
      balance: bankAccount.currentBalance,
    });
  };

  const updateIban = async (bankAccount: BankAccount) => {
    return api.put(API_BANK_ACCOUNTS, {
      bankAccountId: bankAccount.id,
      iban: bankAccount.iban,
    });
  };

  const saveBankAccounts = async (bankAccounts: newBankAccount[] | BankAccount[]) => {
    return api.post(API_BANK_ACCOUNTS, bankAccounts).then((data) => {
      return data;
    });
  };

  const deleteBankAccount = async (bankAccount: BankAccount) => {
    return api.delete(`${API_BANK_ACCOUNTS}/${bankAccount.id}`).then((data) => {
      return data;
    });
  };

  const getBalanceDelta = async (bankAccount: BankAccount, year: number, week: number): Promise<number> => {
    return api.get(`${API_BANK_ACCOUNTS}/predicted-balance-delta/${bankAccount.id}/${year}/${week}`).then(({ data: { data } }) => {
      return data;
    });
  };

  const getBalanceDeltas = async (bankAccount: BankAccount, weeks: { year: number; week: number }[]): Promise<WeekDelta[]> => {
    // Call the new API endpoint with all week objects in the request body
    return api.post(`${API_BANK_ACCOUNTS}/predicted-balance-delta/${bankAccount.id}`, { weeks }).then(({ data: { data } }) => data);
  };

  return {
    bankAccountTypes,
    getBankAccountTypes,
    getBankAccountRoles,
    getBankAccounts,
    saveBankAccounts,
    deleteBankAccount,
    updateBalance,
    updateIban,
    getBankAccountType,
    getBalanceDelta,
    getBalanceDeltas,
  };
}
