import { defineStore } from "pinia";
import { useCustomers } from "@/composables/useCustomers";
import type { ApplicationSettings, BankAccount, Customer } from "@/common/types";
import { computed, ref, watch } from "vue";
// @ts-ignore
import dayjs from "dayjs";
import { useBankAccounts } from "@/composables/useBankAccounts";

export const useCustomerStore = defineStore(
  "customer",
  () => {
    const { getDetails, getApplicationSettings, updateApplicationSettings } = useCustomers();
    const { getBankAccounts } = useBankAccounts();

    const customer = ref<Customer>();
    const onboardingCompleted = ref<boolean>(false);
    const bankAccounts = ref<BankAccount[]>();
    const applicationSettings = ref<Record<string, any>>([]);
    const applicationSettingsLoaded = ref<boolean>(false);

    async function loadCustomer() {
      return getDetails().then((customerData) => {
        customer.value = customerData;

        if (customer.value && customer.value.lastTransactionsUpdate) {
          customer.value.lastTransactionsUpdate = dayjs(customer.value.lastTransactionsUpdate).toDate();
        }
        onboardingCompleted.value = !!customerData.onboardingCompleted;
        loadApplicationSettings();
      });
    }

    async function loadApplicationSettings(): Promise<Record<string, any>> {
      applicationSettings.value = await getApplicationSettings();
      applicationSettingsLoaded.value = true;

      return applicationSettings.value;
    }

    async function saveApplicationSettings(settings: Record<string, any>) {
      console.debug("saveApplicationSettings", settings);
      applicationSettings.value = await updateApplicationSettings(settings);
    }

    async function loadBankAccounts() {
      return getBankAccounts().then((bankAccountData) => {
        bankAccounts.value = bankAccountData.map((bankAccount: BankAccount) => {
          if (bankAccount.lastUpdate) {
            bankAccount.lastUpdate = new Date(bankAccount.lastUpdate);
          }

          return bankAccount;
        });
      });
    }

    const incomeBankAccount = computed((): BankAccount | undefined => {
      if (bankAccounts.value) {
        return bankAccounts.value.find((bankAccount: BankAccount) => bankAccount.bankAccountType?.code === "income");
      }
      return undefined;
    });

    const expensesBankAccount = computed((): BankAccount | undefined => {
      if (bankAccounts.value) {
        return bankAccounts.value.find((bankAccount: BankAccount) => bankAccount.bankAccountType?.code === "expenses");
      }
      return undefined;
    });

    const salaryBankAccount = computed((): BankAccount | undefined => {
      if (bankAccounts.value) {
        return bankAccounts.value.find((bankAccount: BankAccount) => bankAccount.bankAccountType?.code === "salary");
      }
      return undefined;
    });

    const clear = () => {
      customer.value = undefined;
      applicationSettings.value = {};
      onboardingCompleted.value = false;
      bankAccounts.value = [];
    };

    return {
      customer,
      onboardingCompleted,
      bankAccounts,
      incomeBankAccount,
      expensesBankAccount,
      salaryBankAccount,
      loadCustomer,
      loadBankAccounts,
      applicationSettings,
      applicationSettingsLoaded,
      loadApplicationSettings,
      saveApplicationSettings,
      clear,
    };
  },
  {
    persist: {
      storage: sessionStorage,
    },
  },
);
