import dayjs from "dayjs";
import "dayjs/locale/nl";
import "dayjs/locale/en";
import dayOfYear from "dayjs/plugin/dayOfYear";
import isoWeek from "dayjs/plugin/isoWeek";
import isoWeeksInYear from "dayjs/plugin/isoWeeksInYear";

dayjs.locale("nl");
dayjs.extend(dayOfYear);
dayjs.extend(isoWeek);
dayjs.extend(isoWeeksInYear);

// A function to update the locale in dayjs
function updateLocale(locale: string) {
  dayjs.locale(locale);
}

const dateFromISOWeek = (w: number, y: number) => {
  let simple = dayjs(`${y}-01-01`).isoWeek(w);
  if (simple.day() > 4) simple = simple.add(1, "week");
  return simple;
};

const calculateWeekDifference = (fromWeek: string, fromYear: string, toWeek: string, toYear: string): number => {
  // Converts week-year to date
  const fromDate = dateFromISOWeek(Number(fromWeek), Number(fromYear));
  const toDate = dateFromISOWeek(Number(toWeek), Number(toYear));

  // Return difference in weeks
  return toDate.diff(fromDate, "week");
};

const getWeekStart = (week: number, year: number): dayjs.Dayjs => {
  return dayjs(`${year}-01-01`).isoWeek(week).startOf("week");
};

export { dayjs, updateLocale, calculateWeekDifference, getWeekStart };
